import React from "react"
import { rhythm } from "../utils/typography"

import TagLink from "../components/tagLink"

const TagList = ({ tags }) => tags.map((tag: string, i: number) => (
  <span
    key={`tag-${i}`}
    style={{
      marginRight: rhythm(.2),
    }}>
    <TagLink tag={tag}></TagLink>
  </span>
))

export default TagList