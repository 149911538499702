import React from "react"
import { Link } from "gatsby"

import { tagSlug, formatTag } from "../utils/helpers"

const TagLink = ({ tag }) => (
  <Link
    style={{
      display: 'inline-block'
    }}
    to={tagSlug(tag)}
    className="underlineLink"
  >
    {formatTag(tag)}
  </Link>
)

export default TagLink